<template>
  <div>
    <footer>
      <div class="top">
        <div class="top-inner">
          <div class="left">
            <img src="../assets/footer_logo.png" alt="logo">
            <p>服务热线： 400-160-1970</p>
            <p>地址：山东省青岛市市北区敦化路31号卓越世纪中心</p>
          </div>
          <div class="right">
            <ul>
              <li>
                <img src="../assets/real_time_order.png" alt="">
                <span>实时单扫码下载</span>
              </li>
              <li>
                <img src="../assets/appointment_order.png" alt="">
                <span>预约单扫码下载</span>
              </li>
              <li>
                <img src="../assets/we_chat.png" alt="">
                <span>关注迪尔出行微信公众号</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span>&copy;2022青岛路远信息技术有限公司版权所有</span>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'DeFooter',
  }
</script>

<style lang="scss" scoped>
footer {
  color: #fff;
  >.top {
    background-color: rgba(0,0,0,0.8);
    padding-top: 85px;
    padding-bottom: 50px;
    .top-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 65.1vw;
      min-width: 1020px;
      margin: 0 auto;
      > .left {
        > p {
          margin-left: 65px;
          font-size: 18px;
        }
      }
      > .right {
        > ul {
          display: flex;
          align-items: center;
          justify-content: center;
          > li {
            margin-right: 27px;
            > span {
              display: block;
              width: 100%;
              font-size: 13px;
              text-align: center;
            }
          } 
          > li:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  > .bottom {
    text-align: center;
    background-color: #252525;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}
</style>