import { render, staticRenderFns } from "./DeFooter.vue?vue&type=template&id=27f209ca&scoped=true&"
import script from "./DeFooter.vue?vue&type=script&lang=js&"
export * from "./DeFooter.vue?vue&type=script&lang=js&"
import style0 from "./DeFooter.vue?vue&type=style&index=0&id=27f209ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f209ca",
  null
  
)

export default component.exports